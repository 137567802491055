import request from '@/utils/request'

// 分商城新建、编辑提交
export function subIntegralSystemForm (data, type) { //type 0 新建 1 编辑
    return request({
        url: "/small-inteface/goods-service/integralSystem",
        method: type == 1 ? 'put' : 'post',
        data
    })
}

// 根据Id获取限时抢购详情
export function getFlashsaleDetail (id) {
    return request({
        url:  `/small-inteface/buyActivity/${id}`,
        method: 'get'
    })
}

// 获取积分商城列表
export function getIntegralSystemList (data) {
    return request({
        url:  "/small-inteface/goods-service/integralSystem/query",
        method: 'post',
        data
    })
}

// 积分商城上下架
export function upDownShelfIntegralSystem (id, type) { //type 1 上架 0 下架
    return request({
        url: type ?  "/small-inteface/goods-service/integralSystem/upperShelf/" + id : "/small-inteface/goods-service/integralSystem/lowerShelf/" + id,
        method: 'put'
    })
}

//获取限时抢购商品列表
export function getActivityProcudtList (params) {
    return request({
        url:  "/small-inteface/buyActivity/goodsList",
        method: 'get',
        params
    })
}
//获取限时抢购商品列表
//接口：根据id删除积分商城商品
//地址：http://${BASE_URL}/goods-service/integralSystem/{id}
export function deleteActivityProduct (data) {
    return request({
        url:  `/small-inteface/goods-service/integralSystem/${data.id}`,
        method: 'delete',
        data
    })
}