<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="商品名称"
            class="search-input"
            v-model="searchObj.goodsName"
          ></el-input>
          <el-select
            placeholder="状态"
            class="search-input ml-15"
            v-model="searchObj.status"
          >
            <el-option
              v-for="(item, index) in activity_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
              <!-- 门店列表 -->
          <el-tabs
            v-model="searchObj.storeId"
            @tab-click="changeTab"
            class="mt-20"
          >
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in storeList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw mt-20">
            <div class="flx-row ali-c">
              <el-button class="ml-15" type="primary" @click="selectGoods"
                >添加商品</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.infoImgUrl)"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <!-- <el-table-column label="所属门店" prop="storeName"></el-table-column> -->
            <el-table-column label="原价" prop="price"></el-table-column>
            <el-table-column label="兑换条件">
              <template slot-scope="scope">
                <span>{{ scope.row.integral }}积分</span>
                <span v-if="scope.row.money > 0"
                  >+ {{ scope.row.money }}元</span
                >
              </template>
            </el-table-column>
            <el-table-column label="排序" prop="sort"></el-table-column>
            <el-table-column label="兑换数量" prop="total"></el-table-column>
            <el-table-column label="库存" prop="infoStock"></el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="2"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="200">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Credits_convert',
                      query: { id: scope.row.id },
                    }"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-document"></i>
                      兑换记录
                    </el-link>
                  </router-link>
                  <span class="tip-text-info ml-15" @click="edit(scope.row)">
                    <i class="el-icon-edit"></i>
                    编辑
                  </span>
                  <span class="tip-text-delete ml-15" @click="deleteGoods(scope.row)">
                    <i class="el-icon-delete"></i>
                    删除
                  </span>
                  <!-- 一期不做 -->
                  <!-- <router-link :to="{ name: 'Credits_details' }">
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit"></i>
                      详情
                    </el-link>
                  </router-link>-->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      :visible.sync="showRule"
      width="55%"
      title="设置积分规则"
      @close="clearDialog"
    >
      <el-form
        label-width="120px"
        :model="rule_form"
        :rules="rules"
        ref="rule_form"
      >
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            placeholder="输入名称"
            v-model="rule_form.goodsName"
            class="search-input"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="兑换条件"
          :prop="rule_form.exchangeType == 0 ? 'integral' : 'money'"
        >
          <div class="mb-10">
            <el-radio
              v-model="rule_form.exchangeType"
              :label="0"
              @change="changeType"
              >积分</el-radio
            >
            <el-input
              v-if="rule_form.exchangeType == 0"
              placeholder="输入数值"
              class="form-input-200"
              v-model.number="rule_form.integral"
            >
              <template slot="append">分</template>
            </el-input>
          </div>
          <div>
            <el-radio
              v-model="rule_form.exchangeType"
              :label="1"
              @change="changeType"
              >积分 + 金额</el-radio
            >
            <template v-if="rule_form.exchangeType == 1">
              <el-input
                placeholder="输入数值"
                class="form-input-200"
                v-model.number="rule_form.integral"
              >
                <template slot="append">分</template>
              </el-input>
              +
              <el-input
                placeholder="输入数值"
                class="form-input-200"
                v-model="rule_form.money"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </div>
        </el-form-item>
        <el-form-item
                label="排序"
                placeholder="输入数值"
                prop="sort"
        >
          <el-input
                  class="form-input-400"
                  v-model.number="rule_form.sort"
          >
          </el-input>
          <span class="ml-10 c-9 fs-14">数字越小排在越前</span>
        </el-form-item>
        <el-form-item
          label="每人限兑换"
          placeholder="输入数值"
          prop="limitNumber"
        >
          <el-input
            class="form-input-400"
            v-model.number="rule_form.limitNumber"
          >
            <template slot="append">件</template>
          </el-input>
          <span class="ml-10 c-9 fs-14">输入0表示不限制</span>
        </el-form-item>
        <el-form-item label="积分兑换说明" prop="details">
          <el-input
            type="textarea"
            :rows="4"
            maxlength="300"
            show-word-limit
            v-model="rule_form.details"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button
          plain
          size="small"
          class="form-button-w70"
          @click="cancelGoods"
          >取消</el-button
        >
        <el-button
          class="form-button-w70"
          size="small"
          type="primary"
          @click="confirmGoods"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 商品选择弹窗 -->
    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      :hiddenSelectedStore = true
      :storeId ="searchObj.storeId"
      :searchArr ="searchArr"
      @getSelectedList="getSelectedList"
      @getTableList="getTableList"
      :selected="false"
    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import {
  subIntegralSystemForm,
  getIntegralSystemList,
  upDownShelfIntegralSystem,
  deleteActivityProduct
} from "@/api/maketing/credits";
import {
  getStoreList,
  getProcudtList,
} from "@/api/online_department_store/commodity_manage";
export default {
  name: "credits_list",
  components: {
    Pagination,
    DepartmentGoodsDialog,
  },
  data() {
    return {
      // 分页数据
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      // 顶部搜索栏数据
      searchObj: {
        goodsName: "",
        status: null,
        storeId: ""
      },
      //活动状态
      activity_status: [
        {
          id: 1,
          title: "上架",
        },
        {
          id: 2,
          title: "下架",
        },
      ],
      storeList: [],
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      showRule: false,
      rule_form: {
        goodsName: "",
        shareNumber: "",
        exchangeType: 0,
        integral: "",
        money: "",
        limitNumber: "",
        goodsInfoIds: "",
        details: "",
        sort: null
      },
      rules: {
        integral: [
          { required: true, message: "积分不能为空", trigger: "blur" },
        ],
        money: [
          { required: true, message: "积分及金额不能为空", trigger: "blur" },
          {
            pattern: /^\d{1,}(\.{0}|\.{1}\d{0,2})?$/,
            message: "只能为数字",
            trigger: "blur",
          },
        ],
        limitNumber: [
          { required: true, message: "兑换限额不能为空", trigger: "blur" },
        ],
        details: [
          { required: true, message: "兑换说明不能为空", trigger: "blur" },
        ],
      },
      addType: null,
      tableHeader: [
        // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode",
        },
        {
          value: "商品图片",
          key: "imgUrl",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName",
        },
        {
          value: "二级分类",
          key: "secondClassifyName",
        },
        {
          value: "实价",
          key: "price",
        },
        {
          value: "库存",
          key: "stock",
        },
        {
          value: "操作",
          key: "operations",
        },
      ],
      searchArr:[
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称",
        },
        {
          name: "goodsClassifyId",
          value: "",
          placeholder: "请选择商品分类",
        },
        {
          name: "merchantId",
          value: "",
          placeholder: "请选择商户",
        }
      ]
    };
  },
  mounted() {
    this.getStores();

  },
  methods: {
    // 初始化数据
    initData() {
      let data = {
        goodsName: this.searchObj.goodsName,
        storeId: this.searchObj.storeId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        status: this.searchObj.status,
      };

      getIntegralSystemList(data).then((res) => {
        this.tableData = res.data.body.list;
        this.tableData.map(
          (item) => (item.exchangeType = Number(item.exchangeType))
        );
        this.total = res.data.body.total;
      });
    },
    // 编辑与回填
    edit(e) {
      this.addType = 1;
      this.showRule = true;
      let time = e.creationTime.replace("T", " ").split(".")[0];
      this.rule_form = {
        id: e.id,
        barCode: e.barCode,
        goodsName: e.goodsName,
        goodsId: e.goodsId,
        goodsDescribe: e.goodsDescribe,
        shareNumber: e.shareNumber,
        exchangeType: e.exchangeType,
        integral: e.integral,
        money: e.money,
        limitNumber: e.limitNumber,
        details: e.details,
        pictures: e.pictures,
        pictureUrl: e.pictureUrl,
        status: e.status,
        creationTime: time,
        sort: e.sort
      };
    },
    // 搜索事件
    search() {
      this.initData();
    },
    // 重置搜索关键词
    reset() {
      this.searchObj.goodsName = "" ;
      this.searchObj.status = null ;
      this.pageNum = 1;
      this.initData();
    },
    //获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.storeList = res.data.body.list;
        this.searchObj.storeId = res.data.body.list[0].id.toString();
        this.initData();
      });
    },
        // 门店改变
    changeTab(tab, event) {
      // console.log(tab)
      this.pageNum =1;
      this.searchObj.storeId = tab.name;
      this.initData();
    },
    upDownShelf(status, id) {
      upDownShelfIntegralSystem(id, status == 2 ? 0 : 1).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    deleteGoods(e) {
      // console.log(e)
      this.$confirm("此操作将永久删除该商城商品, 是否继续?", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          deleteActivityProduct({id:e.id})
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.initData();
        })
        .catch(() => {

          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeType() {
      this.rule_form.integral = "";
      this.rule_form.money = "";
    },
    clearDialog() {
      this.rule_form = {
        goodsName: "",
        shareNumber: "",
        exchangeType: 0,
        integral: "",
        money: "",
        limitNumber: "",
        goodsInfoIds: "",
        details: "",
        sort: 10000
      };
    },
    cancelGoods(type) {
      this.rule_form.goodsInfoIds = null;
      this.showRule = false;
    },
    confirmGoods() {
      this.$refs["rule_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.confirmGoodsForm();
      });
    },
    async confirmGoodsForm() {
      //addType：0为新增商品，1为编辑商品
      await subIntegralSystemForm(this.rule_form, this.addType).then((res) => {
        this.$message({
          message: this.addType ? "编辑成功" : "添加成功",
          type: "success",
        });
        this.initData();
      });

      this.showRule = false;
    },
    // 获取已勾选商品
    getSelectedList(data, index) {
      // console.log(data)
      // console.log(index)
      //console.log(this.addType)
      this.addType = null;
      this.rule_form.goodsInfoIds = [data.goodsId];
      this.rule_form.goodsName = data.goodsName;
      this.rule_form.goodsDescribe = data.remark;
      this.rule_form.sort = 10000
      this.showRule = true;
    },
    getTableList(data) {
      //根据搜索条件改变列表
      getProcudtList({ goodsStatus: 1, ...data }).then((res) => {
        let data = this.$refs.GoodsDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
      });
    },
    // 打开商品列表弹窗
    selectGoods() {
      let data = this.$refs.GoodsDialog;
      data.act_form.list = [];
      data.selected_temp = [];
      data.showGoods = true;
      data.getGoods(false);
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
